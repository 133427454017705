import React from "react";
import  QRCode  from "react-qr-code";
import "./QRCode.css";

const DownloadQrCode = ({ onClose }) => {
    const url = process.env.REACT_APP_BASE_URL+'download-app'
    console.log("url: ", url)
  const handleBackgroundClick = (e) => {
    if (e.target.classList.contains("qr-modal-bg")) {
      onClose();
    }
  };

  // return (
  //   <div className="qr-modal-bg" onClick={handleBackgroundClick}>
  //     <div className="qr-modal-content">
  //       <button className="qr-modal-close" onClick={onClose}>✖</button>
  //       <QRCode value={url} size={200} />
  //     </div>
  //   </div>
  // );

  return (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <QRCode value={url} size={50} />
          <small>Scan QR to download app</small>
        </div>
  );
};

export default DownloadQrCode;
