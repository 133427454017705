import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './FacebookFeed.css';
import testfab from '../assets/img/home1/testfab.jpg';
import { FaFacebook } from 'react-icons/fa';
import Marquee from 'react-fast-marquee';

const FacebookFeed = () => {
  const [posts, setPosts] = useState([]);
  // const pageId = '401722289700437'; // Your Page ID
  // const accessToken = 'EAASCj3LGvCQBO0K9Nb2o5fnNYQBLWNZCHhkqdiVIPlCJBKSCwtMlUrS6ZCoHJSjZBkQFWUqYHNuSEMpJBzZAdwunccFLevTu9FY5lYgZAxYZAnTp8LzKOa2bpJmZCzBgL9DWbd2kl3RtnAYiSqPSXTjaoQIZBlBI51b2Ml3RvyMAJWZCDrXUcqZAGNJ0mZBJn9RxZA5LZANxtUzUX'; // Your Access Token

  const pageId = process.env.REACT_APP_FACEBOOK_PAGE_ID; // Your Page ID
  const accessToken = process.env.REACT_APP_FACEBOOK_PAGE_ACCESS_TOKEN;
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          `https://graph.facebook.com/v19.0/${pageId}/posts`,
          {
            params: {
              access_token: accessToken,
              fields: 'id,message,full_picture,permalink_url,created_time'
            }
          }
        );
        console.log("response data", response.data);
  
        // Map Facebook fields to your existing structure
        const mappedPosts = response.data.data.map(post => ({
          id: post.id,
          message: post.message || '',
          full_picture: post.full_picture || '',
          permalink: post.permalink_url,
          timestamp: post.created_time,
          media_type: 'image' // Facebook doesn't give media_type, defaulting to 'image'
        }));
  
        setPosts(mappedPosts || []);
  
      } catch (error) {
        console.error('Error fetching Facebook posts:', error);
      }
    };
  
    fetchPosts();
  }, [pageId, accessToken]);
  

  return (
    <div className="facebook-feed-container">
      <div className="feed-header">
        <img
          src={testfab} 
          alt="Page Profile"
          className="page-profile-img"
        />
        <span className="page-name">Testfabrics, Inc. </span>
        {/* <button className="follow-button">
          <a href="https://web.facebook.com/p/Testfabrics-Inc-100070179757613/?_rdc=1&_rdr" target="_blank" rel="noopener noreferrer">
            Follow Us
          </a>
        </button> */}
      </div>
      <Marquee className="posts-marquee" speed={50}>
        <div className="posts-row">
          
          {/* Duplicate posts for infinite loop effect */}
          {posts.map((post) => {
            
            if (!post.message || !post.full_picture) return null;
            return(
              <a
                // href="https://web.facebook.com/p/Testfabrics-Inc-100070179757613/?_rdc=1&_rdr"
                href={post.permalink}
                target="_blank"
                rel="noopener noreferrer"
                key={`${post.id}-duplicate`}
                className="post-card-link"
              >
                <div className="post-card">
                  {post.full_picture && (
                    <img src={post.full_picture} alt="Post" className="post-image" />
                  )}
                  <p className="post-message">
                    {post.message || 'No message available.'}
                  </p>
                  <FaFacebook className="facebook-icon" />
                </div>
              </a>
            )
          }
          )}
        </div>
      </Marquee>
    </div>
  );
};

export default FacebookFeed;
