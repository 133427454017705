import React, {  useContext, useEffect, useState } from 'react';
import { FaDraftingCompass } from 'react-icons/fa';
import ServicesTwoCard from './SevicesTwoCard';
import { UserContext } from '../../App';
import { useApi } from '../../middleware/middleware';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { extractIdFromUrlPath,concatUrlPath } from '../../helpers/concatUrlPath';
import { Helmet } from 'react-helmet';
function Services8() {
    const { values } = useContext(UserContext);
    const location = useLocation();
    const history = useHistory();
    
    // Retrieve categoryId and sucategoryId from context or localStorage
    const [categoryId,setCategoryId] = useState(null);
    const [subcategoryId,setSubcategoryId] = useState(
        extractIdFromUrlPath(location.pathname || '')
    );
    
    useEffect(()=>{
        // get sub category from url
        setSubcategoryId(
            extractIdFromUrlPath(location.pathname || '')
        )
    },[location.pathname])

    


    const { data, error, isLoading } = useApi('testfabrics_standard_products_by_subcategory', {
        methods: subcategoryId,
        standards: categoryId
    });

    const { data:breadCrumb, error:breadCrumb_error, isLoading:breadCrumb_isLoading } = useApi('standard_product_breadcrumb', {
        method_id: subcategoryId,
    });

    useEffect(()=>{
        setCategoryId(breadCrumb?.[0].standards__ID);
    },[breadCrumb]);

    console.log("breadCrumb: ",breadCrumb);

    
    return (
        <>
            <Helmet>
                <title>{`Testfabrics.com: ${breadCrumb?.[0].methods__Name.slice(0,50) || 'PRODUCT BY STANDARDS'}`}</title>
            </Helmet>
            <section className="page-banner-wrap-2 bg-cover">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="breadcrumb-wrap">
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to={'/'}>Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                           <Link to={
                                            breadCrumb?.[0] ? 
                                            concatUrlPath('product-by-standards-subcategory',
                                                            breadCrumb?.[0]?.standards__Name,
                                                            breadCrumb?.[0]?.standards__ID
                                                        )
                                            : '/product-by-standards'
                                           }> {breadCrumb?.[0]?.standards__Name}</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                           <Link to={location.pathname}> {breadCrumb?.[0]?.methods__Name}</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {"Products"}
                                        </li>
                                    </ol>
                                </nav>
                            </div>

                            <div className="page-heading">
                                <h2>{"Products"}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-service-wrapper section-padding mtm-30">
                <div className="container">
                    <div className="row">
                        {data?.length === 0 && <h4>No Products Found</h4>}
                        {data?.map((data, index) => (
                            <ServicesTwoCard
                                key={data.product__ID}
                                productId={data.product__ID}
                                thumbnail={data.product__ID}
                                defaultImg={process.env.REACT_APP_IMAGE_URL + 'images/product_testfabrics.jpg'}
                                icon={<FaDraftingCompass />}
                                heading={data.product__Name}
                                text={data?.product__Description?.slice(0, 95) + "..."}
                                subheading={data?.product__Number}
                                index={index}
                            />
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Services8;
