import React from 'react'
import CookiePolicy from '../components/Cookie/CookiePolicy'

const CookiePage = () => {
  return (
    <div>
        <CookiePolicy/>
    </div>
  )
}

export default CookiePage