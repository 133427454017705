import { useState } from "react";
import Header3 from "../Header3";
import Footer3 from "../Footer3";
import PageBanner from "../PageBanner";
import bannerBg from '../../assets/img/page-banner.jpg'
import CustomerDashboardView from "./CustomerDashboardView";
import { isTokenValid, getPayload } from "../../helpers/jwt";
import { toast, ToastContainer } from "react-toastify"
import { useHistory } from "react-router-dom";


function CustomerDashboard() {

    const history = useHistory();

    if(!isTokenValid()){
        console.log("invalid token")
        toast.error('Login to access your dashboard.', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
        });
        setTimeout(() => {
            history.push('/customer-portal');
        }, 2500);
    }

    const [customerId,setCustomerId] = useState(getPayload().id);
    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                theme="colored"
            />
            <Header3 />
            <PageBanner bannerBg={bannerBg} heading="Customer Dashboard" currentPage="Dashboard" />
            <CustomerDashboardView customerId={customerId} />
            <Footer3 />
        </>
    );
}

export default CustomerDashboard;
