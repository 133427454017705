/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import CategoryBtn from '../ProjectFilter/CategoryBtn';
import FilterItem from '../ProjectFilter/FilterItem';
import projectFilterData from '../ProjectFilter/projectFilterData';
import { Helmet } from 'react-helmet';
import { useApi } from '../../middleware/middleware';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { tabs,addRowCount } from './customerTabs';
import "./Dashboard.css";



function CustomerDashboardView({customerId}) {
    // STATES
    const [category, setCategory] = useState('product');
    const [data, setData] = useState([]);
    const [page,setPage] = useState(1);
    const [perPage,setPerPage] = useState(10);
    const [totalRows,setTotalRows] = useState(0);
    
    // useEffect to handle the category change and perpage change
    useEffect(() => {
        let mount = true;
        console.log('id: ',customerId)
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}${tabs[category].api}`,{
                    params: {
                        'customerId' : customerId,
                        'page': 1,
                        'limit': perPage
                    }
                })
                const result = addRowCount(res?.data?.data,page,perPage)
              
                setData(result);
                setTotalRows(res?.data?.totalCount);
                setPage(1);               
            } catch (error) {
                console.log(`Error fetching ${category} data: `,error)
            }
        }
        setData([]);
        fetchData();

    }, [category,perPage]);
    

    // useEffect to handle pagination
    useEffect(() => {
        let mount = true;
        console.log('id: ',customerId)
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}${tabs[category].api}`,{
                    params: {
                        'customerId' : customerId,
                        'page': page,
                        'limit': perPage
                    }
                })
                console.log("data fetched")
                const result = addRowCount(res?.data?.data,page,perPage)
                setData(result);
                setTotalRows(res?.data?.totalCount);     
            } catch (error) {
                console.log(`Error fetching ${category} data: `,error)
            }
        }

        fetchData();

    },[page]);



    console.log(data);

    return (
        <>
            <Helmet>
            <title>{`Testfabrics.com: ${tabs[category].label.toUpperCase()}`}</title>

            </Helmet>
            <section className="portfolio-wrapper-section section-padding">
                <div className="container">
                    {/* <div className="row">
                        <div className="col-12 col-lg-12 text-center">
                            <div className="section-title-2">
                                <span>Portfolio</span>
                                <p>Portfolio</p>
                                <h1>Our Case Study</h1>
                            </div>
                        </div>
                    </div> */}

                    <div className="row mb-20">
                        <div className="col-12 col-lg-12 text-center">
                            <div className="portfolio-cat-filter">
                                {
                                    Object.entries(tabs).map(([key,value]) => {
                                        return (
                                            <CategoryBtn
                                                key={key}
                                                className={category === key ? 'btn-active' : null}
                                                name={key}
                                                label={value.label}
                                                handleSetCategory={setCategory}
                                            />
                                        )
                                    })
                                }
                                
                                
                            </div>
                        </div>
                    </div>

                    <div className="row grid">
                        <DataTable
                            columns={tabs[category].columns}
                            data={data}
                            responsive
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangePage={(newPage) => setPage(newPage)}
                            onChangeRowsPerPage={(newPerPage, currentPage) => setPerPage(newPerPage)}
                        />
                    </div>
                </div>
            </section>
        </>
    );
}

export default CustomerDashboardView;
