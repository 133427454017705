const tabs = {
    'product': {
        'api': 'customer_buy_product_history',
        'label': 'Product Buy History',
        'columns': [
            {
                name: '#',
                selector: row => row.serialNo,
                sortable: true,
            },
            {
                name: 'Name',
                selector: row => row.name,
                sortable: true,
            },
            {
                name: 'Email Address',
                selector: row => row.email_address,
            },
            {
                name: 'Product Name',
                selector: row => row.product_name,
            },
            {
                name: 'Country',
                selector: row => row.country_name,
            },
            {
                name: 'Order Date',
                selector: row => row.create_date_time,
                sortable: true,
            },
        ]
    },
    'msds': {
        'api': 'customer_msds_history',
        'label': 'MSDS History',
        'columns': [
            {
                name: '#',
                selector: row => row.serialNo,
                sortable: true,
            },
            {
                name: 'Product Code',
                selector: row => row.product_item_code,
                sortable: true,
            },
            {
                name: 'Email Address',
                selector: row => row.email_address,
            },
            {
                name: 'Product Name',
                selector: row => row.product_name,
            },
            {
                name: 'Order Date',
                selector: row => row.create_date_time,
                sortable: true,
            },
        ]
    },
    'qoute': {
        'api': 'customer_quote_history',
        'label': 'Qoute History',
        'columns': [
            {
                name: '#',
                selector: row => row.serialNo,
                sortable: true,
            },
            {
                name: 'Full name',
                selector: row => row.full_name,
                sortable: true,
                cell: row => (
                    <div dangerouslySetInnerHTML={{ __html: row.full_name }} />
                )
            },
            {
                name: 'Phone',
                selector: row => row.phone_numbers,
                cell: row => (
                    <div dangerouslySetInnerHTML={{ __html: row.phone_numbers }} />
                )
            },
            {
                name: 'Company',
                selector: row => row.companies,
                cell: row => (
                    <div dangerouslySetInnerHTML={{ __html: row.companies }} />
                )
            },
            {
                name: 'emails',
                selector: row => row.emails,
                cell: row => (
                    <div dangerouslySetInnerHTML={{ __html: row.emails }} />
                )
            },
            {
                name: 'Address',
                selector: row => row.addresses,
                cell: row => (
                    <div dangerouslySetInnerHTML={{ __html: row.addresses }} />
                )
            },
            {
                name: 'Order Date',
                selector: row => row.order_date,
                sortable: true,
            },
        ]
    },
    'order': {
        'api': 'customer_order_history',
        'label': 'Order History',
        'columns': [
            {
                name: '#',
                selector: row => row.serialNo,
                sortable: true,
            },
            {
                name: 'Full name',
                selector: row => row.full_name,
                sortable: true,
                cell: row => (
                    <div dangerouslySetInnerHTML={{ __html: row.full_name }} />
                )
            },
            {
                name: 'Phone',
                selector: row => row.phone_numbers,
                cell: row => (
                    <div dangerouslySetInnerHTML={{ __html: row.phone_numbers }} />
                )
            },
            {
                name: 'Company',
                selector: row => row.companies,
                cell: row => (
                    <div dangerouslySetInnerHTML={{ __html: row.companies }} />
                )
            },
            {
                name: 'emails',
                selector: row => row.emails,
                cell: row => (
                    <div dangerouslySetInnerHTML={{ __html: row.emails }} />
                )
            },
            {
                name: 'Address',
                selector: row => row.addresses,
                cell: row => (
                    <div dangerouslySetInnerHTML={{ __html: row.addresses }} />
                )
            },
            {
                name: 'Order Date',
                selector: row => row.order_date,
                sortable: true,
            },
        ]
    },
    'coc': {
        'api': 'customer_coc_history',
        'label': 'Certificate of Conformity History',
        'columns': [
            {
                name: '#',
                selector: row => row.serialNo,
                sortable: true,
            },
            {
                name: 'Lot Number',
                selector: row => row.product_lot_number,
                sortable: true,
            },
            {
                name: 'Email Address',
                selector: row => row.email_address,
            },
            {
                name: 'Product Name',
                selector: row => row.product_name,
            },
            {
                name: 'Order Date',
                selector: row => row.create_date_time,
                sortable: true,
            },
        ]
    },
    'swatch': {
        'api': 'customer_swatch_history',
        'label': 'Swatch History',
        'columns': [
            {
                name: '#',
                selector: row => row.serialNo,
                sortable: true,
            },
            {
                name: 'Company',
                selector: row => row.Fabrics_Swatch_Request__Company_Name,
                sortable: true,
            },
            {
                name: 'Person',
                selector: row => row.Fabrics_Swatch_Request__Company_person,
            },
            {
                name: 'Title',
                selector: row => row.Fabrics_Swatch_Request__Title,
            },
            {
                name: 'Email',
                selector: row => row.Fabrics_Swatch_Request__Email,
                sortable: true,
            },
            {
                name: 'Address',
                selector: row => row.Fabrics_Swatch_Request__Address,
                sortable: true,
            },
            {
                name: 'City',
                selector: row => row.Fabrics_Swatch_Request__City,
                sortable: true,
            },
            {
                name: 'State',
                selector: row => row.Fabrics_Swatch_Request__State,
                sortable: true,
            },
            {
                name: 'Country',
                selector: row => row.Fabrics_Swatch_Request__Country,
                sortable: true,
            },
            {
                name: 'Zip Code',
                selector: row => row.Fabrics_Swatch_Request__Zip_Code,
                sortable: true,
            },
        ]
    },
    'complain': {
        'api': 'customer_complain_history',
        'label': 'Complains History',
        'columns': [
            {
                name: '#',
                selector: row => row.serialNo,
                sortable: true,
            },
            {
                name: 'Name',
                selector: row => row.name,
                sortable: true,
            },
            {
                name: 'Email',
                selector: row => row.email,
            },
            {
                name: 'Lot Number',
                selector: row => row.lot_number,
            },
            {
                name: 'Item Number',
                selector: row => row.item_number,
                sortable: true,
            },
            {
                name: 'Description',
                selector: row => row.description,
                sortable: true,
            },
            {
                name: 'Date',
                selector: row => row.created_at,
                sortable: true,
            },
        ]
    }
}

function addRowCount(data,page,perPage){

    const updatedData = data.map((item, index) => ({
        ...item,
        serialNo: ((page - 1) * perPage) + index + 1
    }));
    return updatedData;
  
}


export {tabs, addRowCount}