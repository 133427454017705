import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const AppDownloadRedirect = () => {
  const history = useHistory();

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      // Redirect Android users
      window.location.href = "https://play.google.com/store/apps/details?id=com.tech.testfabrics.test_fabrics";
    } else if (/iPhone|iPad|iPod/.test(userAgent)) {
      // Redirect iOS users
      window.location.href = "https://apps.apple.com/us/app/testfabrics-product-validation/id1660715463";
    } else {
      // Optional fallback for desktop or unknown devices
      history.push("/");
    }
  }, [history]);

  return <p>Redirecting to the app store...</p>;
};

export default AppDownloadRedirect;
