function isTokenValid() {
    try {
        const token = localStorage.getItem('auth_token');
        if (!token) return false;

        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c =>
            '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        ).join(''));

        const payload = JSON.parse(jsonPayload);

        // Validate expiration
        const currentTime = Math.floor(Date.now() / 1000);
        if (!payload.exp || payload.exp < currentTime) {
            console.log("Date expire");
            return false;
        };

        // Validate presence of customer_id
        if (!payload.id) {
            console.log("no customer id");
            return false
        };

        return true;
    } catch (error) {
        console.log('Token validation error:', error);
        return false;
    }
}


function getPayload() {

    const token = localStorage.getItem('auth_token');
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c =>
        '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''));

    const payload = JSON.parse(jsonPayload);
    return payload;
}



export {isTokenValid, getPayload}